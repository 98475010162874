<template>
  <div>
    <!-- Summary action buttons/ dropdown -->
    <div class="mb-3">
      <div
        v-if="!isOpenFromExternalCallShare"
        class="d-flex summary-block"
      >
        <button
          v-for="summaryType in summaryVariants"
          :key="summaryType.key"
          class="summary-button"
          :class="{'active-summary': summaryType.key==currentSummaryTab}"
          @click="getSummaryData(summaryType.key)"
        >
          {{summaryType.title}}
        </button>
      </div>
      <div
        v-else
        class="summary-dropdown"
      >
        <inner-label-dropdown
          :dropdownItems="summaryVariantsWithSummary"
          :label="staticText.summaryTypeLabel"
          :placeholder="staticText.summaryTypeLabel"
          :value="summaryVariantsWithSummary[0] || null"
          :allowEmpty="false"
          dropdownLabel="title"
          track-by="key"
          @input="(summaryType) => getSummaryData(summaryType.key)"
        />
      </div>
      <div v-if="!!currentActiveSummary" class="d-flex justify-content-end mt-2">
        <button
          class="regenerate-btn ml-2 h-100"
          @click="toggleEditMode"
          :disabled="isLoading"
        >
          <img
            :src="isEditing ? require('@/assets/svgs/save-icon.svg') : require('@/assets/svgs/edit-icon.svg')"
            class="mr-1"
            svg-inline
          />
          {{isEditing ? staticText.saveSummaryLabel : staticText.editSummaryLabel}}
        </button>
        <button
          v-if="isEditing"
          class="regenerate-btn ml-2 h-100"
          @click="cancelEdit"
          :disabled="isLoading"
        >
          <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'mr-1'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="a" fill="#fff"><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z"/></mask><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z" fill="#000" fill-opacity=".5"/><path d="M7 .705l.707.707.707-.707-.707-.707L7 .705zM6.295 0l.707-.707-.707-.707-.707.707.707.707zM3.5 2.795l-.707.707.707.707.707-.707-.707-.707zM.705 0l.707-.707-.707-.707-.707.707L.705 0zM0 .705l-.707-.707-.707.707.707.707L0 .705zM2.795 3.5l.707.707.707-.707-.707-.707-.707.707zM0 6.295l-.707-.707-.707.707.707.707L0 6.295zM.705 7l-.707.707.707.707.707-.707L.705 7zM3.5 4.205l.707-.707L3.5 2.79l-.707.707.707.707zM6.295 7l-.707.707.707.707.707-.707L6.295 7zM7 6.295l.707.707.707-.707-.707-.707L7 6.295zM4.205 3.5l-.707-.707-.707.707.707.707.707-.707zM7.707-.002l-.705-.705L5.588.707l.705.705L7.707-.002zm-2.12-.705L2.794 2.088l1.414 1.414L7.002.707 5.588-.707zm-1.38 2.795L1.412-.707-.002.707l2.795 2.795 1.414-1.414zM-.003-.707l-.704.705L.707 1.412l.705-.705L-.002-.707zm-.704 2.12l2.795 2.794 1.414-1.414L.707-.002-.707 1.412zm2.795 1.38L-.707 5.588.707 7.002l2.795-2.795-1.414-1.414zm-2.795 4.21l.705.704 1.414-1.414-.705-.705-1.414 1.414zm2.12.704l2.794-2.795-1.414-1.414-2.795 2.795 1.414 1.414zm1.38-2.795l2.795 2.795 1.414-1.414-2.795-2.795-1.414 1.414zm4.21 2.795l.704-.705-1.414-1.414-.705.705 1.414 1.414zm.704-2.12L4.912 2.794 3.498 4.207l2.795 2.795 1.414-1.414zm-2.795-1.38l2.795-2.795L6.293-.002 3.498 2.793l1.414 1.414z" fill="#2A2D52" fill-opacity=".01" mask="url(#a)"/></svg>
          {{staticText.cancelSummaryLabel}}
        </button>
      </div>
    </div>
    <!-- Summary details -->
    <div
      v-if="!!currentActiveSummary"
      ref="transcriptSummary"
      :class="['mb-2 summary-container', { 'no-scroll' : isOpenFromExternalCallShare }]"
    >
      <div v-if="isBulletPointSummary">
        <div
          :contenteditable="isEditing"
          @input="handleSummaryEdit"
          class="editable-content"
        >
          <ul class="bullet-point-summary">
            <li
              v-for="(line, index) in currentActiveSummary"
              :key="index"
            >
              {{line}}
            </li>
          </ul>
        </div>
      </div>
      <div v-else-if="isTopicBlockSummary">
        <div
          :contenteditable="isEditing"
          @input="handleSummaryEdit"
          class="editable-content"
        >
          <div
            v-for="(summary, summaryIndex) in currentActiveSummary"
            :key="'summary-' + summaryIndex"
          >
            <h6>{{ summary.title }} ({{ summary.time_range }})</h6>
            <ul>
              <li
                v-for="(detail, index) in summary.details"
                :key="'detail-' + summaryIndex + '-' + index"
              >
                {{ detail }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-else-if="isActionItemsSummary">
        <div
          :contenteditable="isEditing"
          @input="handleSummaryEdit"
          class="editable-content"
        >
          <ul>
            <li v-for="(summary, summaryIndex) in currentActiveSummary" :key="'summary-' + summaryIndex">
              <h6>{{ summary.title }}</h6>
              <ul>
                <li>{{ summary.details }}</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div v-else-if="isEmailSummary">
        <div class="editable-content email-content">
          <template v-if="isEditing">
            <input
              type="text"
              v-model="emailEditData.subject"
              class="email-subject-input"
            />
            <div class="email-body-container">
              <textarea
                v-model="emailEditData.body"
                class="email-body-input"
                rows="5"
              ></textarea>
            </div>
          </template>
          <template v-else>
            <h6>{{ currentActiveSummary.subject }}</h6>
            <p v-for="(emailBody, emailBodyIndex) in currentActiveSummary.body" :key="'emailBody-' + emailBodyIndex">
              {{ emailBody }}
            </p>
          </template>
        </div>
      </div>
      <div v-else-if="isCustomSummary">
        <div
          :contenteditable="isEditing"
          @input="handleSummaryEdit"
          class="editable-content"
        >
          <div
            v-for="(summary, summaryIndex) in currentActiveSummary"
            :key="'summary-' + summaryIndex"
          >
            <h6>{{ summary.title }} ({{ summary.time_range }})</h6>
            <ul>
              <li
                v-for="(detail, index) in summary.details"
                :key="'detail-' + summaryIndex + '-' + index"
              >
                {{ detail }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          :contenteditable="isEditing"
          @input="handleSummaryEdit"
          class="editable-content"
        >
          {{ currentActiveSummary }}
        </div>
      </div>

      <!-- Summary disclaimer -->
      <div class="summary-disclaimer">
        {{ staticText.summaryDisclaimer }}
      </div>
    </div>
    <div v-else>
      {{ staticText.noSummaryAvailableText }}
    </div>
  </div>
</template>

<script>
import { SUMMARY_TYPES } from "@/apps/call_history/CallFeedbackComponents/constants"
import { mapActions, mapGetters } from "vuex"
import { setCookie, getCookie } from "@/utils/utils"
import InnerLabelDropdown from "@/apps/base/InnerLabelDropdown.vue"
import { parseBulletPoints, parseTopicBlocks, parseActionItems } from "../utils"

export default {
  name: "BaoVideoSummary",
  components: {
    InnerLabelDropdown
  },
  data () {
    return {
      currentSummaryTab: "default",
      isEditing: false,
      editedContent: {},
      emailEditData: {
        subject: "",
        body: ""
      },
      staticTextDefault: {
        noSummaryAvailableText: "Sadly no summary available for this call.",
        defaultSummaryLabel: "Default",
        shortSummaryLabel: "Short",
        longSummaryLabel: "Long",
        bulletpointsSummaryLabel: "Bulletpoints",
        topicBlocksSummaryLabel: "Topic Blocks",
        actionItemsSummaryLabel: "Action Items",
        emailSummaryLabel: "E-Mail",
        customSummaryLabel: "Custom Topic Blocks",
        summaryDisclaimer: "This summary was computer generated and might contain errors.",
        summaryTypeLabel: "Summary type",
        editSummaryLabel: "Edit",
        saveSummaryLabel: "Save",
        cancelSummaryLabel: "Cancel"
      },
      SUMMARY_TYPES
    }
  },
  props: {
    isOpenFromExternalCallShare: {
      default: false
    }
  },
  mounted () {
    this.setupSummaryView()
  },
  computed: {
    ...mapGetters({
      isMeetingEnded: "callVideoStore/isMeetingEnded",
      meetingSummary: "callVideoStore/getMeetingSummary"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    summaryVariants () {
      return [
        {
          title: this.staticText.defaultSummaryLabel,
          key: "default"
        },
        {
          title: this.staticText.shortSummaryLabel,
          key: "short"
        },
        {
          title: this.staticText.longSummaryLabel,
          key: "long"
        },
        {
          title: this.staticText.bulletpointsSummaryLabel,
          key: "bulletpoints"
        },
        {
          title: this.staticText.topicBlocksSummaryLabel,
          key: "topic_blocks"
        },
        {
          title: this.staticText.actionItemsSummaryLabel,
          key: "action_items"
        },
        {
          title: this.staticText.emailSummaryLabel,
          key: "email"
        },
        {
          title: this.staticText.customSummaryLabel,
          key: "custom"
        }
      ]
    },
    summaryVariantsWithSummary () {
      return this.summaryVariants.filter(summaryVariant => this.doesSummaryExist(summaryVariant))
    },
    currentActiveSummary () {
      return this.meetingSummary[this.currentSummaryTab]
    },
    isBulletPointSummary () {
      return this.currentSummaryTab === this.SUMMARY_TYPES.BULLETPOINTS
    },
    isTopicBlockSummary () {
      return this.currentSummaryTab === this.SUMMARY_TYPES.TOPIC_BLOCKS
    },
    isActionItemsSummary () {
      return this.currentSummaryTab === this.SUMMARY_TYPES.ACTION_ITEMS
    },
    isEmailSummary () {
      return this.currentSummaryTab === this.SUMMARY_TYPES.EMAIL
    },
    isCustomSummary () {
      return this.currentSummaryTab === this.SUMMARY_TYPES.CUSTOM
    }
  },
  methods: {
    ...mapActions({
      setCurrentSummaryText: "callSummaryStore/setCurrentSummaryText",
      updateTranscriptSummary: "callVideoStore/updateTranscriptSummary"
    }),
    setCurrentSummaryTabFromCookie () {
      const favoriteSummaryFormat = getCookie("favoriteSummaryFormat")
      this.currentSummaryTab = favoriteSummaryFormat || "default"
    },
    getSummaryData (summaryType) {
      // Exit edit mode if we're editing
      if (this.isEditing) {
        this.isEditing = false
      }

      // Clear edit data
      this.editedContent = {}
      this.emailEditData = {
        subject: "",
        body: ""
      }

      // Set the "favorite" summary format to clicked tab
      setCookie("favoriteSummaryFormat", summaryType)
      this.currentSummaryTab = summaryType
      this.setCurrentSummary()
    },
    setupSummaryView () {
      if (!this.isOpenFromExternalCallShare) this.setCurrentSummaryTabFromCookie()
      else this.currentSummaryTab = this.summaryVariantsWithSummary[0].key || null
    },
    async copyTranscriptSummary () {
      const transcriptSummary = this.$refs.transcriptSummary

      if (!transcriptSummary) {
        console.error("Transcript summary element not found")
        return
      }

      try {
        const range = document.createRange()
        range.selectNodeContents(transcriptSummary)
        const selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)

        const fragment = range.cloneContents()
        const div = document.createElement("div")
        div.appendChild(fragment)

        const { ClipboardItem } = window
        await navigator.clipboard.write([
          new ClipboardItem({
            "text/html": new Blob([div.innerHTML], { type: "text/html" }),
            "text/plain": new Blob([transcriptSummary.innerText], { type: "text/plain" })
          })
        ])
        // Display the "copied" popup
        this.$emit("show-copy-success")
      } catch (err) {
        console.error("Failed to copy content with format", err)
        const textToCopy = this.$refs.transcriptSummary.innerText
        this.$emit("copy-text", textToCopy)
      } finally {
        const selection = window.getSelection()
        selection.removeAllRanges()
      }
    },
    async setCurrentSummary () {
      await this.sleep(100)
      const transcriptSummary = this.$refs.transcriptSummary
      const summaryText = transcriptSummary ? transcriptSummary.innerText : ""
      this.setCurrentSummaryText({ summaryText })
    },
    doesSummaryExist (summaryVariant) {
      // depending on the format of the summary, it checks if there's summary available or not
      const meetingSummary = this.meetingSummary[summaryVariant.key]
      if (!meetingSummary) return false
      if (typeof meetingSummary === "string" || (typeof meetingSummary === "object" && Array.isArray(meetingSummary))) return meetingSummary.length
      else if (typeof meetingSummary === "object" && !Array.isArray(meetingSummary)) return Object.keys(meetingSummary).length
      return false
    },
    toggleEditMode () {
      if (this.isEditing) {
        // Save mode - parse and update the content
        const parsedContent = this.parseEditedContent()
        if (parsedContent) {
          this.updateTranscriptSummary({
            callId: this.callId,
            summaryFormat: this.currentSummaryTab,
            content: parsedContent
          })
        }
      } else {
        // Enter edit mode
        if (this.currentSummaryTab === SUMMARY_TYPES.EMAIL) {
          const emailData = this.meetingSummary[this.currentSummaryTab]
          this.emailEditData = {
            subject: emailData.subject,
            body: emailData.body.join("\n\n")
          }
        }
      }
      this.isEditing = !this.isEditing
    },
    cancelEdit () {
      // Reset email edit data if we were editing email
      if (this.currentSummaryTab === SUMMARY_TYPES.EMAIL) {
        const emailData = this.meetingSummary[this.currentSummaryTab]
        this.emailEditData = {
          subject: emailData.subject,
          body: emailData.body.join("\n\n")
        }
      }
      // Clear any edited content
      this.editedContent = {}
      this.isEditing = false
    },
    handleSummaryEdit (event) {
      if (this.currentSummaryTab === SUMMARY_TYPES.EMAIL) {
        return // Email content is handled through v-model
      }
      this.$set(this.editedContent, this.currentSummaryTab, event.target.innerHTML)
    },
    parseEditedContent () {
      if (this.currentSummaryTab === SUMMARY_TYPES.EMAIL) {
        return {
          subject: this.emailEditData.subject,
          body: this.emailEditData.body.split("\n\n").filter(text => text.trim())
        }
      }

      const content = this.editedContent[this.currentSummaryTab]
      if (!content) return null

      switch (this.currentSummaryTab) {
        case SUMMARY_TYPES.BULLETPOINTS:
          return parseBulletPoints(content)
        case SUMMARY_TYPES.TOPIC_BLOCKS:
        case SUMMARY_TYPES.CUSTOM:
          return parseTopicBlocks(content)
        case SUMMARY_TYPES.ACTION_ITEMS:
          return parseActionItems(content)
        default:
          return content.replace(/<[^>]*>/g, "").trim()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.summary-block{
  overflow-x: auto;
}

.summary-dropdown {
  width: 100%;
  @include media-breakpoint-up(sm) {
    width: 300px;
  }
}

.summary-button {
  padding: 9px 10px;
  border-radius: 12px;
  line-height:14px;
  justify-content: center;
  font-size: 12px;
  align-items: center;
  background-color: $slate06;
  outline: none;
  border: none;
  color: $slate80;
  height: fit-content;
  margin-right: 8px;
  &:last-child {
    margin-right: 0;
  }
}

.active-summary {
  background-color: #FF56321F !important;
  color: #FF5632 !important;
}

.summary-container {
  color: #00000080;
  overflow-y: scroll;
  max-height: 300px;

  &.no-scroll {
    max-height: unset;
  }
}

.bullet-point-summary {
  padding-left: 16px;
  margin-bottom: 0;
}

.summary-disclaimer {
  color: $slate40;
  font-size: 12px;
}

.editable-content {
  margin: 0 2px; // Add small margin to prevent border cutoff

  &[contenteditable="true"] {
    outline: 2px solid $slate20;
    padding: 8px 12px; // Increased horizontal padding
    border-radius: 8px;
    margin: 0; // Remove margin when editing

    &:focus {
      outline: 2px solid $primary;
    }
  }

  // Preserve list styles while editing
  ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 8px; // Add some bottom margin to lists
  }

  h6 {
    margin-top: 16px;
    margin-bottom: 8px;
    font-weight: 600;
  }

  p {
    margin-bottom: 8px; // Add some bottom margin to paragraphs
  }
}

.email-content {
  .email-subject-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 12px;
    border: 1px solid $slate20;
    border-radius: 4px;
    font-weight: 600;

    &:focus {
      outline: 2px solid $primary;
      border-color: transparent;
    }
  }

  .email-body-input {
    width: 100%;
    padding: 8px;
    border: 1px solid $slate20;
    border-radius: 4px;
    resize: vertical;
    min-height: 100px;

    &:focus {
      outline: 2px solid $primary;
      border-color: transparent;
    }
  }
}

.regenerate-btn {
  padding: 8px 10px;
  line-height: 14px;
  svg {
    height: 16px;
    width: 16px;
  }
}

</style>
