export function parseBulletPoints (content) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(content, "text/html")
  const items = Array.from(doc.querySelectorAll("li")).map(li => li.textContent.trim())
  return items
}

export function parseTopicBlocks (content) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(content, "text/html")
  const blocks = []

  const sections = doc.querySelectorAll("div")
  sections.forEach(section => {
    const titleEl = section.querySelector("h6")
    if (!titleEl) return

    const titleMatch = titleEl.textContent.match(/(.*?)\s*\((.*?)\)/)
    if (!titleMatch) return

    const details = Array.from(section.querySelectorAll("li")).map(li => li.textContent.trim())

    blocks.push({
      title: titleMatch[1].trim(),
      time_range: titleMatch[2].trim(),
      details
    })
  })

  return blocks
}

export function parseActionItems (content) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(content, "text/html")
  const items = []

  const sections = doc.querySelectorAll("li")
  sections.forEach(section => {
    const titleEl = section.querySelector("h6")
    const detailEl = section.querySelector("ul li")
    if (!titleEl || !detailEl) return

    items.push({
      title: titleEl.textContent.trim(),
      details: detailEl.textContent.trim()
    })
  })

  return items
}

export function parseEmail (content) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(content, "text/html")

  const titleEl = doc.querySelector("h6")
  const subject = titleEl ? titleEl.textContent.trim() : ""
  const body = Array.from(doc.querySelectorAll("p")).map(p => p.textContent.trim())

  return {
    subject,
    body
  }
}
