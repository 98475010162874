
export const SUMMARY_TYPES = {
  DEFAULT: "default",
  SHORT: "short",
  LONG: "long",
  BULLETPOINTS: "bulletpoints",
  TOPIC_BLOCKS: "topic_blocks",
  ACTION_ITEMS: "action_items",
  EMAIL: "email",
  CUSTOM: "custom"
}
